import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NgTranslator } from '@nirby/player';
import { NirbyContext } from '@nirby/runtimes/context';
import { PopperConfig, PopPosition } from '@nirby/js-utils/embed';

@Component({
    selector: 'nirby-activation-card',
    templateUrl: './activation-card.component.html',
    styleUrls: ['./activation-card.component.scss'],
})
/**
 * The Popper!
 */
export class ActivationCardComponent implements OnInit, OnChanges {
    /**
     * Context to used by the Popper
     * @param value - The new context
     */
    @Input()
    public set context(value: NirbyContext) {
        this.contextSubject.next(value);
    }

    @Input() public position: PopPosition = 'BOTTOM-LEFT-CORNER';
    @Input() public content?: PopperConfig;
    @Input() public showCloseButton = true;

    private readonly contextSubject = new BehaviorSubject<NirbyContext>(
        new NirbyContext(this.translator)
    );

    /**
     * Constructor.
     * @param translator The translator to use
     */
    constructor(private readonly translator: NgTranslator) {}

    contentController = new BehaviorSubject<PopperConfig | null>(null);

    rawContent$: Observable<PopperConfig> = this.contentController
        .asObservable()
        .pipe(
            filter((c) => !!c),
            map((s) => s as PopperConfig)
        );

    globalMemory$ = this.contextSubject.pipe(
        map((context) => context.memory.mask('global'))
    );
    content$: Observable<PopperConfig> = combineLatest([
        this.rawContent$,
        this.globalMemory$.pipe(
            switchMap((memory) => memory.state$.pipe(map(() => memory)))
        ),
    ]).pipe(
        map(([raw, memory]) => {
            const transformed = { ...raw };
            transformed.buttonLabel = memory.transform(raw.buttonLabel);
            transformed.body = memory.transform(raw.body);
            return transformed;
        })
    );

    @Output() public clicked = new EventEmitter();
    @Output() public closed = new EventEmitter();

    /**
     * The background of the popper
     */
    get backgroundUrl(): string | null {
        const image = this.content?.imageSrc;
        if (!image) return null;
        if (typeof image === 'string') {
            return image;
        }
        return image.lastUrl ?? null;
    }

    /**
     * Handle Popper content changes
     * @param changes - The changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['content']) {
            this.contentController.next(this.content ?? null);
        }
    }

    /**
     * Set the initial content
     */
    ngOnInit(): void {
        this.contentController.next(this.content ?? null);
    }
}
