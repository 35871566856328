import { PopperDesign } from '@nirby/js-utils/embed';

const IS_LOCALHOST = '127.0.0.1' === window.location.hostname;

export const environment = {
    production: false,
    localhost: false,
    api: {
        host: IS_LOCALHOST
            ? 'http://localhost:5200'
            : 'https://nirby-api-gateway-6ks3oygl.uc.gateway.dev',
    },
    firebase: {
        apiKey: 'AIzaSyB4ivv7dLE0f0_M-LmROhwcqy9PmS0aHos',
        authDomain: 'nirby-dev.firebaseapp.com',
        databaseURL: 'https://nirby-dev.firebaseio.com',
        projectId: 'nirby-dev',
        storageBucket: 'nirby-dev.appspot.com',
        messagingSenderId: '515419887477',
        appId: '1:515419887477:web:161951c76fe2c50e0ee53c',
        measurementId: 'G-JNS7WXJLS2',
    },
    stories: {
        stopIfClickedFor: 200,
    },
    vimeo: {
        apiKey: '303c9759f9a6dff82c4cbab97e78daff',
    },
    responsive: {
        baseResolution: [1920, 1080],
        baseFontSizePx: 10,
    },
    assetsWidgetsPath: 'dev/widgets',
    iframe: {
        containerId: 'nby-pop-instance-container',
        styles: {
            active: { width: '100%', height: '100%' },
            inactive: {
                small: { width: 60, height: 60 },
                standard: { width: 260, height: 145 },
                large: { width: 220, height: 290 },
            } as Record<PopperDesign, { width: number; height: number }>,
        },
    },
    useEmulators: IS_LOCALHOST,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
