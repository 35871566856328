import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {animate, AnimationEvent, AnimationMetadataType, state, style, transition, trigger} from '@angular/animations';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {AnalyticsService, ContactsService, NgTranslator, Vector2} from '@nirby/player';
import {NirbyContext} from '@nirby/runtimes/context';
import {ParentService} from '../../services';
import {CompiledPop} from '@nirby/models/pop';
import {Card} from '@nirby/models/nirby-player';
import {filter, first, Subscription} from 'rxjs';
import {ActionEvent} from '@nirby/analytics-typings';
import {PopperDesign, PopPosition} from '@nirby/js-utils/embed';

export interface PlayerError {
    type: 'card-not-found';
}

@Component({
    selector: 'nirby-pop-player',
    templateUrl: './pop-player.component.html',
    styleUrls: ['./pop-player.component.scss'],
    animations: [
        trigger('cardSlideTrigger', [
            state('shown, *', {
                offset: 0,
                styles: {
                    transform: 'translate(0,0) scale(1) skew(0,0)',
                    opacity: 1,
                },
                type: AnimationMetadataType.State,
            }),
            state('hidden, void', {
                offset: 0,
                styles: {
                    transform: 'translate(15%, 5%) scale(.8) skew(-5deg,-5deg)',
                    opacity: 0,
                },
                type: AnimationMetadataType.State,
            }),
            transition('hidden <=> shown', [animate('100ms')]),
            transition(':enter', [animate('100ms')]),
            transition(':leave', [animate('100ms')]),
        ]),
        trigger('fadeTrigger', [
            transition(':enter', [
                style({opacity: 0}),
                animate('100ms', style({opacity: 1})),
            ]),
            transition(':leave', [animate('100ms', style({opacity: 0}))]),
        ]),
    ],
})
/**
 * The Pop Player to play a Pop Popper and all of its cards.
 */
export class PopPlayerComponent implements OnInit, OnChanges, OnDestroy {
    /**
     * The margin between the pop and the edge of the screen.
     */
    get margin(): Vector2 {
        return this.campaign?.welcome.margin ?? {x: 20, y: 20};
    }

    /**
     * The current card data.
     */
    public get currentCard(): Card | null {
        if (this.currentCardKey && this.campaign) {
            return this.campaign.cards[this.currentCardKey];
        }
        return null;
    }

    /**
     * Constructor.
     * @param contacts The contacts service.
     * @param parent The parent service.
     * @param analytics The analytics service.
     * @param translator The translator service.
     */
    constructor(
        private readonly contacts: ContactsService,
        private readonly parent: ParentService,
        private readonly analytics: AnalyticsService,
        private readonly translator: NgTranslator,
    ) {
    }

    /**
     * If the Pop is currently showing a card.
     */
    public get playing(): boolean {
        return !!this.currentCard || !!this.nextCardKey;
    }

    /**
     * If the Pop requires a change because the current card key has changed.
     */
    public get requiresChange(): boolean {
        return this.currentCardKey !== this.nextCardKey;
    }

    @Input() public context = new NirbyContext(this.translator);
    @Input() public position: PopPosition = 'BOTTOM-LEFT-CORNER';
    @Input() public campaign?: CompiledPop;
    @Input() public appendMargin = false;
    @Input() public showCloseButton = true;

    @Output() public activeUpdate = new EventEmitter<boolean>();
    @Output() public closedPlayer = new EventEmitter<boolean>();
    @Output() public errored = new EventEmitter<PlayerError>();

    private currentCardKey?: string;
    committedCard: Card | null = null;
    public nextCardKey?: string;

    public activated = false;
    public closed = false;
    loading = false;
    closeIcon = faTimes;

    @ViewChild('lightbox') lightbox?: ElementRef;
    @ViewChild('contactForm') contactForm?: ElementRef;

    private readonly subscriptions = new Subscription();
    private contextSubscription: Subscription | null = null;

    /**
     * The classes of the container for styling.
     */
    get containerClasses(): Record<string, boolean> {
        const classes: Record<string, boolean> = {
            active: !!(this.activated && this.nextCardKey),
            'campaign-container-centered': this.position === 'CENTER',
        };

        if (this.campaign) {
            const classList: string[] = [];

            classList.push(
                'popper-size-' + (this.campaign.welcome.design ?? 'standard'),
            );
            if (this.campaign.welcome.openingStyle !== 'fullscreen') {
                classList.push('mode-mini', 'right-bottom');
            } else {
                classList.push('mode-fullscreen');
            }

            let cls: string;
            for (cls of classList) {
                classes[cls] = true;
            }
        }
        return classes;
    }

    /**
     * Show/hide the Pop.
     */
    togglePlayer(): void {
        this.closed = !this.closed;
    }

    /**
     * Restart the Pop.
     */
    async restart(): Promise<void> {
        this.prepareNextCard(this.campaign?.firstCardKey);
        // start listening for actions
        if (!this.contextSubscription) {
            this.contextSubscription = this.analytics.trackContext(
                this.context,
                true,
                true,
            );
            await this.context.waitForContact();
        }
        await this.context.triggerEvent({
            type: 'view',
            tags: [],
            properties: {},
        });
    }

    /**
     * Handle key presses.
     * @param event The key event.
     */
    @HostListener('window:keyup', ['$event'])
    onKeyUp(event: KeyboardEvent): void {
        const key = event.key?.toLowerCase();
        if (key === 'escape') {
            this.prepareEnd();
        }
    }

    /**
     * Starts the ending animation (hides the current card)
     */
    prepareEnd(): void {
        this.prepareNextCard();
    }

    /**
     * Start animating to show the next card.
     */
    public async commitNextCard(): Promise<void> {
        if (this.requiresChange) {
            // switch card
            this.currentCardKey = this.nextCardKey;
            this.committedCard = this.currentCard;
            this.activeUpdate.emit(!!this.currentCardKey);
            if (this.currentCardKey) {
                await this.context.waitForContact();
                this.context.triggerEvent({
                    type: 'view-card',
                    tags: [],
                    properties: {
                        cardId: this.currentCardKey,
                    },
                });
            }
        }
        if (this.closed) {
            // closed Popper
            this.parent.destroyContainer();
            this.committedCard = null;
            this.closedPlayer.emit();
        }
        this.updateParentSize();
    }

    /**
     * Emits a signal to the parent window to update the container
     */
    public updateParentSize(): void {
        const iframe = {
            containerId: 'nby-pop-instance-container',
            styles: {
                active: {width: '100%', height: '100%'},
                inactive: {
                    small: {width: 60, height: 60},
                    standard: {width: 260, height: 145},
                    large: {width: 220, height: 290},
                } as Record<PopperDesign, { width: number; height: number }>,
            },
        };
        if (!iframe || !this.campaign) {
            return;
        }
        const design = this.campaign.welcome.design ?? 'standard';
        const activeStyle = iframe.styles.inactive[design];
        const isActive = !!this.nextCardKey;

        const margin = isActive
            ? {x: 0, y: 0}
            : this.campaign.welcome.margin ?? {x: 20, y: 20};

        const newStyle = isActive
            ? iframe.styles.active
            : {
                width: activeStyle.width + 'px',
                height: activeStyle.height + 'px',
            };
        this.parent.resizeContainer(
            margin.x + 'px',
            margin.y + 'px',
            newStyle.width,
            newStyle.height,
        );
    }

    /**
     * Handle animation end.
     * @param event The animation event.
     */
    onCardAnimationEvent(event: AnimationEvent): void {
        if (event.phaseName === 'done') {
            const activationIsHidden = event.toState === 'void';
            this.updateParentSize();
            if (activationIsHidden) {
                // on card hidden
                this.commitNextCard().then();

                const started =
                    this.playing && event.element.id === 'activation-card';
                const ended = !this.playing && event.element.id === 'card';
                if (started) {
                    this.activated = true;
                }
                if (ended) {
                    this.context.triggerEvent({
                        type: 'close',
                        tags: [],
                        properties: {},
                    });
                    this.activated = false;
                }

                if (started || ended) {
                    this.updateParentSize();
                }
            }
        }
    }

    /**
     * Starts loading the next card
     * @param key The key of the next card
     * @private
     */
    private prepareNextCard(key?: string): void {
        this.currentCardKey = undefined;
        if (key && this.campaign && !(key in this.campaign.cards)) {
            this.nextCardKey = undefined;
            this.errored.emit({
                type: 'card-not-found',
            });
            this.togglePlayer();
            return;
        }
        this.nextCardKey = key;
        this.loading = true;
    }

    /**
     * Sets the action listeners for the full Pop
     */
    ngOnInit(): void {
        this.updateParentSize();
        this.context.actionListener.setListener('card-link', (action) => {
            if (action.options.key) {
                this.prepareNextCard(action.options.key);
            }
        });
        this.context.actionListener.setListener('go-to-url', (action) => {
            const target = action.options.target;
            this.parent.goToUrl(action.options.href, target ? target : '_self');
        });
        this.context.actionListener.setListener('trigger-dom', (action) => {
            this.parent.triggerDom(
                action.options.selector,
                action.options.event,
            );
        });
        this.context.actionListener.setListener('close', () => {
            this.prepareEnd();
        });

        let triggeredFirstAction = false;
        this.subscriptions.add(
            this.context.events$
                .pipe(
                    filter(
                        (event): event is ActionEvent => event.type === 'action',
                    ),
                    first(),
                )
                .subscribe(() => {
                    if (!triggeredFirstAction) {
                        triggeredFirstAction = true;
                        this.context.triggerEvent({
                            properties: {},
                            tags: [],
                            type: 'view-effective',
                        });
                    }
                }),
        );
    }

    /**
     * Handles changes on the widget (?)
     * @param changes The changes
     *
     * @deprecated Does this really do anything?
     */
    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['widget']) {
            if (this.campaign) {
                this.nextCardKey = undefined;
                this.updateParentSize();
                await this.commitNextCard();
            }
        }
    }

    /**
     * Unsubscribes from all subscriptions.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.contextSubscription?.unsubscribe();
    }

    /**
     * Marks the Pop as already loaded
     */
    onLoad(): void {
        this.loading = false;
    }
}
