import {Card, defaultConfig} from '@nirby/models/nirby-player';
import {PopperConfig} from '@nirby/js-utils/embed';
import {CompiledPop} from '@nirby/models/pop';

const popper: PopperConfig = {
    body: 'Hi, {{email}}! I\'m a Popper',
    buttonLabel: 'Click me!',
    design: 'standard',
    imageSrc: 'https://picsum.photos/200',
    margin: {
        x: 20,
        y: 20,
    },
};

const firstCard: Card = {
    blocks: [
        {
            hash: 'button',
            actions: {
                click: [
                    {
                        id: null,
                        type: 'card-link',
                        options: {
                            key: 'second',
                        },
                    },
                    {
                        id: null,
                        type: 'trigger-dom',
                        options: {
                            event: 'click',
                            selector: '#counter-a',
                        },
                    },
                ],
            },
            position: [
                {x: 25, y: defaultConfig.cardSize.y - 75},
                {
                    x: defaultConfig.cardSize.x - 25,
                    y: defaultConfig.cardSize.y - 25,
                },
            ],
            rotation: 0,
            type: 'Button',
            style: {
                borderRadius: 5,
                borderWidth: 0,
                borderColor: '#000000',
                fontFamily: 'Ubuntu',
                fontSize: 20,
                fontStyle: 'bold',
                color: '#420ccb',
                fontColor: null,
                alignment: 'between',
            },
            content: {
                label: 'Click me!',
                icon: null,
            },
        },
        {
            hash: 'slider',
            actions: {
                click: [],
            },
            position: [
                {x: 50, y: 50},
                {x: 300, y: 200},
            ],
            rotation: -15,
            type: 'Slider',
            style: {},
            content: {
                title: 'Weeeno',
                color: '#3b14d3',
                emoji: '🥰',
                submitMessage: 'Nice!',
            },
        },
        {
            hash: 'question',
            actions: {
                click: [],
            },
            position: [
                {x: 50, y: 190},
                {x: 270, y: 330},
            ],
            rotation: 15,
            type: 'Question',
            style: {},
            content: {
                question: '¿Qué tal?',
                successLabel: 'Nice!',
            },
        },
    ],
    hash: 'first',
    style: {
        fillColor: '#ffffff',
        strokeColor: '#000000',
        borderRadius: 5,
    },
    title: 'First!',
};

const secondCard: Card = {
    blocks: [
        {
            hash: 'button-mv',
            actions: {
                click: [
                    {
                        id: null,
                        type: 'open-embed',
                        options: {
                            src: 'https://www.youtube.com/embed/{{youtubeId}}',
                        },
                    },
                ],
            },
            position: [
                {x: 25, y: defaultConfig.cardSize.y - 250},
                {
                    x: defaultConfig.cardSize.x - 25,
                    y: defaultConfig.cardSize.y - 125,
                },
            ],
            rotation: 0,
            type: 'Button',
            style: {
                borderRadius: 5,
                borderWidth: 4,
                borderColor: '#000000',
                fontFamily: 'Ubuntu',
                fontSize: 20,
                fontStyle: 'bold',
                color: 'rgba(0,0,0,0)',
                fontColor: null,
                alignment: 'between',
            },
            content: {
                label: 'Watch MV (id: {{youtubeId}})',
                icon: null,
            },
        },
        {
            hash: 'button',
            actions: {
                click: [
                    {
                        id: null,
                        type: 'card-link',
                        options: {
                            key: 'first',
                        },
                    },
                ],
            },
            position: [
                {x: 25, y: defaultConfig.cardSize.y - 75},
                {
                    x: defaultConfig.cardSize.x - 25,
                    y: defaultConfig.cardSize.y - 25,
                },
            ],
            rotation: 0,
            type: 'Button',
            style: {
                borderRadius: 5,
                borderWidth: 0,
                borderColor: '#000000',
                fontFamily: 'Ubuntu',
                fontSize: 20,
                fontStyle: 'bold',
                color: '#420ccb',
                fontColor: null,
                alignment: 'between',
            },
            content: {
                label: 'Go back',
                icon: null,
            },
        },
        {
            hash: 'slider',
            actions: {
                click: [],
            },
            position: [
                {x: 50, y: 50},
                {x: 300, y: 200},
            ],
            rotation: 15,
            type: 'Slider',
            style: {
                borderRadius: 5,
                borderWidth: 0,
                borderColor: '#000000',
            },
            content: {
                title: 'Weeena',
                color: '#3b14d3',
                emoji: '🥰',
                submitMessage: 'Nice!',
            },
        },
        {
            hash: 'question',
            actions: {
                click: [],
            },
            position: [
                {x: 50, y: 190},
                {x: 270, y: 330},
            ],
            rotation: -15,
            type: 'Question',
            style: {},
            content: {
                question: 'What\'s up?',
                successLabel: 'Nice!',
            },
        },
    ],
    hash: 'second',
    style: {
        fillColor: '#ffffff',
        strokeColor: '#000000',
        borderRadius: 10,
    },
    title: 'Second!',
};

export const TESTING_POP: CompiledPop = {
    cards: {
        [firstCard.hash]: firstCard,
        [secondCard.hash]: secondCard,
    },
    firstCardKey: 'first',
    plugins: {},
    variableDeclaration: {
        youtubeId: {
            initialValue: 'VPLUwh3ON70',
            type: 'string',
            source: 'query',
        },
        email: {
            initialValue: '😺',
            type: 'string',
            source: 'query',
        },
    },
    title: 'Pop',
    welcome: popper,
};
