import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Card} from '@nirby/models/nirby-player';
import {NgTranslator} from '@nirby/player';
import {NirbyContext} from '@nirby/runtimes/context';

@Component({
    selector: 'nirby-pop-content',
    templateUrl: './pop-content.component.html',
    styleUrls: ['./pop-content.component.scss'],
})
/**
 * Shows a card for Pop
 */
export class PopContentComponent {
    @Input() public card: Card | null = null;
    @Input() context = new NirbyContext(this.translator);

    @Output() loaded = new EventEmitter<void>();

    /**
     * Constructor.
     * @param translator The translator to use
     */
    constructor(
        private readonly translator: NgTranslator
    ) {
    }
}
