<button
    id="counter-a"
    class="btn btn-brand-primary"
    (click)="countA = countA + 1"
>
    A: {{ countA }}
</button>
<button
    id="counter-b"
    class="btn btn-brand-secondary"
    (click)="countB = countB + 1"
>
    B: {{ countB }}
</button>
<div #container></div>
