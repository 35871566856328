<div
    class="directive-inner"
    [ngClass]="['popper-' + position.toLowerCase()]"
    [ngStyle]="{
        backgroundImage:
            'url(' +
            (backgroundUrl
                ? backgroundUrl
                : '/assets/img/bg/nby-pop-button.jpg') +
            '), url(/assets/img/bg/nby-pop-button.jpg)'
    }"
>
    <a
        href="#"
        class="btn-close"
        id="btn-close-button"
        *ngIf="showCloseButton"
        (click)="
            closed.emit(); $event.preventDefault(); $event.stopPropagation()
        "
    >
        <fa-icon [icon]="iconClose"></fa-icon>
    </a>
    <div class="content">
        <h1 class="title">
            {{ body.length > 75 ? (body | slice: 0:75) + '...' : body }}
        </h1>

        <div class="actions mt-3">
            <button
                class="btn btn-sm btn-block btn-radius btn-icon btn-light px-3"
                id="btn-activate-player"
                (click)="popped.emit()"
            >
                <span class="text flex-1">{{ label }}</span>
                <fa-icon [icon]="iconArrowRight"></fa-icon>
            </button>
        </div>
    </div>
</div>
