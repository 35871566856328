import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {join} from '@fireflysemantics/join';
import {environment} from '../../environments/environment';
import {Logger} from '@nirby/logger';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {CompiledPop, PublishedJson, PublishedRoute, PublishedWidget} from '@nirby/models/pop';
import {TESTING_POP} from '../utils/testing-pop';
import {PUBLISHED_MIGRATOR, RouteMatcherService} from '@nirby/pop';
import {Modeled, WithId} from '@nirby/store/models';

@Injectable({
    providedIn: 'root',
})
export class PublishedService {
    private readonly migrator = PUBLISHED_MIGRATOR;

    constructor(
        protected af: AngularFirestore,
        private http: HttpClient,
        private matcher: RouteMatcherService,
    ) {
    }

    /**
     * Get routes from a workspace
     * @param widgetId Workspace id
     */
    public async getRoutes(widgetId: string): Promise<PublishedRoute[]> {
        const widget = await this.af
            .collection('published')
            .doc<PublishedWidget>(widgetId)
            .ref.get()
            .then((result) => result.data());
        return Object.values(widget?.routes ?? {});
    }

    /**
     * Get a compiled pop from a workspace
     * @param widgetId Workspace ID
     * @param popId Pop ID
     */
    async getCompiledPop(
        widgetId: string,
        popId: string,
    ): Promise<CompiledPop | null> {
        const data = await this.af
            .collection('published')
            .doc(widgetId)
            .collection('campaigns')
            .doc<Modeled<PublishedJson>>(popId)
            .ref.get();
        let jsonData: PublishedJson | undefined = data.data();
        if (!jsonData) {
            return null;
        }
        jsonData = this.migrator.migrate(jsonData).migrated;
        return JSON.parse(jsonData.json) as CompiledPop;
    }

    /**
     * Get a compiled pop from a workspace at certain path
     * @param widgetId Workspace ID
     * @param path Path where a Pop should be
     */
    public async getPopForPath(
        widgetId: string,
        path: string,
    ): Promise<WithId<CompiledPop> | null> {
        if (!environment.production && widgetId === 'test-pop') {
            return {
                _docId: 'test-pop',
                ...TESTING_POP,
            };
        }
        const routes: PublishedRoute[] = await this.getRoutes(widgetId);
        const matchingRoutes = routes.filter((r) =>
            r ? this.matcher.match(r.path, path) : false,
        );
        const match = matchingRoutes[matchingRoutes.length - 1];

        if (!match) {
            Logger.warnStyled(
                'PUBLISHED',
                `No match route found for path "${path}" at widget "${widgetId}"`,
            );
            return null;
        }
        if (match.campaigns.length === 0) {
            Logger.warnStyled(
                'PUBLISHED',
                `No campaigns found on matching route for path "${path}" at widget "${widgetId}"`,
            );
            return null;
        }
        const compiled = await this.getCompiledPop(
            widgetId,
            match.campaigns[0].id,
        );
        if (!compiled) {
            Logger.warnStyled(
                'PUBLISHED',
                `Campaign for matching route for path "${path}" at widget "${widgetId} not found"`,
            );
            return null;
        }
        return {
            _docId: match.campaigns[0].id,
            ...compiled,
        };
    }

    public getPath(id: string, xpPath = 'main.json'): string {
        const basePath = '/assets/';
        return join(basePath, environment.assetsWidgetsPath, id, xpPath);
    }

    public getCardLocal(widgetId: string, cardId: string): Observable<unknown> {
        const cardPath = this.getPath(widgetId, `cards/${cardId}.json`);
        return this.http.get(cardPath).pipe(
            map(
                (data) =>
                    ({
                        id: cardId,
                        ...data,
                    } as unknown),
            ),
        );
    }

    public getCompiledPopLocal(id: string): Observable<CompiledPop> {
        const mainPath = this.getPath(id);
        return this.http.get(mainPath).pipe(map((data) => data as CompiledPop));
    }
}
