import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Logger} from '@nirby/logger';
import { NirbyPop } from '../nby-pop-dev';

@Component({
    selector: 'nirby-player-test-embed',
    templateUrl: './player-test-embed.component.html',
    styleUrls: ['./player-test-embed.component.scss'],
})
/**
 * PlayerTestEmbedComponent is the component that is used to test the player in an embedded environment.
 */
export class PlayerTestEmbedComponent implements AfterViewInit {
    @ViewChild('container') container?: ElementRef;
    countA = 0;
    countB = 0;

    /**
     * Constructor.
     * @param route The activated route service.
     */
    constructor(private route: ActivatedRoute) {}

    /**
     * Creates the embedded player.
     */
    ngAfterViewInit(): void {
        const widgetId =
            this.route.snapshot.paramMap.get('workspaceId') ??
            'uHeFIBD5gtdMdWkcy2s2';

        const queryParams: { [key: string]: string } = {};
        new URLSearchParams(window.location.search).forEach(
            (value, key) => (queryParams[key] = value),
        );
        const config = {
            apiKey: this.route.snapshot.queryParamMap.get('key') ?? undefined,
            parent: this.container?.nativeElement,
            destroyable: false,
            overrideOrigin: window.location.origin,
            overridePath: this.route.snapshot.queryParamMap.get('path') ?? '/',
            queryParams,
        };
        const pop = new NirbyPop(widgetId, config);
        Logger.logStyled('POP', 'Initialized using config', config);
        if (!pop.initialized) {
            console.warn('Initialization failed');
        }
    }
}
