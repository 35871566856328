import { Component, OnDestroy, OnInit } from '@angular/core';
import { FullProductId } from '@nirby/analytics-typings';
import {Contact, NirbyContext } from '@nirby/runtimes/context';
import { Subscription } from 'rxjs';
import { TESTING_POP } from '../../utils/testing-pop';

@Component({
    selector: 'nirby-player-test',
    templateUrl: './player-test.component.html',
    styleUrls: ['./player-test.component.scss'],
})
/**
 * Test component for nirby-player
 */
export class PlayerTestComponent implements OnInit, OnDestroy {
    public pop = TESTING_POP;
    isPlayerActive = false;
    context = NirbyContext.mock();

    subscription = new Subscription();

    /**
     * Initializes the context
     */
    ngOnInit(): void {
        this.context.init(
            Contact.unauthenticated(),
            new FullProductId('nirby', 'player', 'pop'),
            this.pop.variableDeclaration ?? {}
        );
        this.subscription.add(this.context.actionListener.subscribe());
    }

    /**
     * Unsubscribes from the context
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
