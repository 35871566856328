import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { PopPosition } from '@nirby/js-utils/embed';

@Component({
    selector: 'nirby-small-popper',
    templateUrl: './small-popper.component.html',
    styleUrls: ['./small-popper.component.scss'],
})
export class SmallPopperComponent {
    @Input() position: PopPosition = 'BOTTOM-LEFT-CORNER';
    @Input() backgroundUrl: string | null = null;

    @Output() closed = new EventEmitter();
    @Output() popped = new EventEmitter();

    iconClose = faTimes;
    iconArrowRight = faArrowRight;
}
