import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    ActivationCardComponent,
    LargePopperComponent,
    PopContentComponent,
    PopPlayerComponent,
    SmallPopperComponent,
    StandardPopperComponent,
} from './components';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AnalyticsService, ContactsService, FontSizerService, NirbyPlayerModule} from '@nirby/player';
import {ReactiveFormsModule} from '@angular/forms';
import {defaultConfig, NirbyPlayerConfig} from '@nirby/models/nirby-player';
import {NIRBY_PLAYER_CONFIG} from '@nirby/shared/player-config';
import {ApiService} from '@nirby/shared/api';

@NgModule({
    declarations: [
        ActivationCardComponent,
        PopPlayerComponent,
        PopContentComponent,
        SmallPopperComponent,
        StandardPopperComponent,
        LargePopperComponent,
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        NirbyPlayerModule,
        ReactiveFormsModule,
    ],
    exports: [
        ActivationCardComponent,
        PopPlayerComponent,
        PopContentComponent,
        SmallPopperComponent,
        StandardPopperComponent,
        LargePopperComponent,
    ],
})
export class NirbyPopModule {
    // eslint-disable-next-line valid-jsdoc
    /**
     * @param config Nirby Player configuration
     * @returns Module with providers
     */
    public static forRoot(config: {
        player: Partial<NirbyPlayerConfig>;
    }): ModuleWithProviders<NirbyPopModule> {
        return {
            ngModule: NirbyPopModule,
            providers: [
                {
                    provide: NIRBY_PLAYER_CONFIG,
                    useValue: {
                        ...defaultConfig,
                        ...config.player,
                    },
                },
                {
                    provide: ApiService,
                    useClass: ApiService,
                },
                {
                    provide: ContactsService,
                    useClass: ContactsService,
                },
                {
                    provide: AnalyticsService,
                    useClass: AnalyticsService,
                },
                {
                    provide: FontSizerService,
                    useClass: FontSizerService,
                },
            ],
        };
    }
}
