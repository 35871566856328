<div
    class="directive-inner"
    (click)="popped.emit(); $event.stopPropagation()"
    [ngClass]="['popper-' + position.toLowerCase()]"
    [ngStyle]="{
        backgroundImage:
            'url(' +
            (backgroundUrl
                ? backgroundUrl
                : '/assets/img/bg/nby-pop-button-small.jpg') +
            '), url(/assets/img/bg/nby-pop-button-small.jpg)'
    }"
>
    <a
        href="#"
        *ngIf="false"
        class="btn-close"
        id="btn-close-button"
        (click)="
            closed.emit(); $event.preventDefault(); $event.stopPropagation()
        "
    >
        <fa-icon [icon]="iconClose"></fa-icon>
    </a>
</div>
