import { Component, Input, Output, EventEmitter } from '@angular/core';
import { faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { PopPosition } from '@nirby/js-utils/embed';

@Component({
    selector: 'nirby-standard-popper',
    templateUrl: './standard-popper.component.html',
    styleUrls: ['./standard-popper.component.scss'],
})
export class StandardPopperComponent {
    @Input() position: PopPosition = 'BOTTOM-LEFT-CORNER';
    @Input() backgroundUrl: string | null = null;
    @Input() body = '';
    @Input() label = '';
    @Input() public showCloseButton = true;

    @Output() closed = new EventEmitter();
    @Output() popped = new EventEmitter();

    iconClose = faTimes;
    iconArrowRight = faArrowRight;
}
