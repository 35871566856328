import {Injectable} from '@angular/core';
import {ParentWindow} from '@nirby/js-utils/embed';

@Injectable({
    providedIn: 'root',
})
/**
 * The same as {@link ParentWindow} but with Angular DI.
 */
export class ParentService extends ParentWindow {
}
