<div *ngIf="campaign" [ngClass]="containerClasses" class="campaign-container">
    <!-- Campaign Button -->
    <nirby-activation-card
        id="activation-card"
        [ngStyle]="
            appendMargin
                ? {
                      right: campaign.welcome.margin.x + 'px',
                      bottom: campaign.welcome.margin.y + 'px'
                  }
                : {}
        "
        *ngIf="!nextCardKey && !activated && !closed"
        @cardSlideTrigger
        [position]="position"
        [context]="context"
        [content]="campaign.welcome"
        [showCloseButton]="showCloseButton"
        (@cardSlideTrigger.done)="onCardAnimationEvent($event)"
        (closed)="togglePlayer()"
        (clicked)="restart()"
    ></nirby-activation-card>

    <!-- Backdrop -->
    <div
        class="backdrop"
        @fadeTrigger
        (click)="prepareEnd()"
        *ngIf="activated && nextCardKey"
    ></div>

    <!-- Card -->
    <div
        id="card"
        *ngIf="currentCard && !requiresChange"
        [@cardSlideTrigger]="loading ? 'hidden' : 'shown'"
        (@cardSlideTrigger.done)="onCardAnimationEvent($event)"
    >
        <div class="card-inner">
            <div class="nirby-pop-top">
                <button id="close-btn" (click)="prepareEnd()">
                    <fa-icon [icon]="closeIcon"></fa-icon>
                </button>
            </div>
            <nirby-pop-content
                [context]="context"
                [card]="committedCard"
                (loaded)="onLoad()"
            ></nirby-pop-content>
            <div class="nirby-pop-bottom text-center">
                <span class="brand"
                    >Powered by
                    <a href="https://pop.nir.by" target="_blank"
                        >Nirby Pop</a
                    ></span
                >
            </div>
        </div>
    </div>
</div>
<nirby-context-modals [context]="context"></nirby-context-modals>
