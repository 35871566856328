import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'nirby-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
/**
 * The pop-embed App component.
 */
export class AppComponent implements OnInit {
    /**
     * Constructor.
     * @param translate Translate service.
     */
    constructor(private readonly translate: TranslateService) {
    }

    /**
     * Initializes the language module
     */
    ngOnInit(): void {
        this.translate.setDefaultLang('en');
        const language = this.translate.getBrowserLang();
        if (language) {
            this.translate.use(language);
        }
    }
}
