/* eslint-disable @typescript-eslint/no-explicit-any */
interface NirbyPopConfig {
    apiKey: string;
    overrideOrigin: string;
    parent?: HTMLElement;
    overridePath: string;
    destroyable: boolean;
    queryParams?: { [key: string]: string };
}

const STORAGE_KEYS = {
    CONTACT_TOKEN: 'contactToken',
    ANSWERS: 'answers',
};

interface PopContainer {
    parent: HTMLDivElement;
    iframe: HTMLIFrameElement;
}

/**
 * Nirby Pop high-level controller
 */
export class NirbyPop {
    /**
     * Constructor.
     * @param workspaceId Workspace ID
     * @param config Nirby Pop general configuration
     */
    constructor(
        workspaceId: string,
        private config: Partial<NirbyPopConfig> = {}
    ) {
        if (config.overrideOrigin) {
            this.popOrigin = config.overrideOrigin;
        }

        const container = NirbyPop.createContainer();
        const queryParams = config?.queryParams ?? {};
        const iframe = NirbyPop.createIframe(
            this.popOrigin + '/embed/' + workspaceId,
            queryParams
        );

        this.container = NirbyPop.merge(container, iframe, config.parent);

        //const initData: PickParentMessage<'init'> = {
        const initData = {
            type: 'init',
            data: {
                path: config.overridePath ?? window.location.pathname,
                apiKey: config.apiKey,
                contact: NirbyPop.getItem(STORAGE_KEYS.CONTACT_TOKEN),
                impressed: NirbyPop.getItem('impressed') ?? false,
                // persistent: NirbyPop.getItem<Record<string, NirbyVariableNullable>>('persistent') ?? {}
                persistent:
                    NirbyPop.getItem<Record<string, unknown>>('persistent') ??
                    {},
            },
        };

        iframe.onload = () => {
            iframe.contentWindow?.postMessage(initData, this.popOrigin);
        };

        window.addEventListener('message', (message) => {
            if (message.origin !== this.popOrigin) {
                return;
            }
            this.onMessage(message.data);
        });
    }

    /**
     * If this controller has been initialized correctly
     */
    get initialized(): boolean {
        return !!this.container;
    }

    container?: PopContainer;

    popOrigin = 'https://popappplay.nir.by';

    /**
     * Transforms a storage key to a Nirby specific key
     * @param key Storage key
     *
     * @returns storageKey Nirby specific key
     */
    static storageKey(key: string): string {
        return `_nirby.${key}`;
    }

    /**
     * Get item from session storage
     * @param key Storage key
     *
     * @returns item The stored item at the given key
     */
    static getItem<T>(key: string): T | null {
        try {
            const parsed = this.storageKey(key);
            const item = sessionStorage.getItem(parsed);
            return item ? JSON.parse(item) : null;
        } catch (e) {
            console.warn(`Could not find "${key}" on session storage`);
            return null;
        }
    }

    /**
     * Set item to session storage
     * @param key Storage key
     * @param value Value to store
     */
    static setItem<T>(key: string, value: T): void {
        sessionStorage.setItem(this.storageKey(key), JSON.stringify(value));
    }

    /**
     * Stores a contact token into the storage
     * @param contactToken Contact token
     */
    private static storeContact(contactToken: string): void {
        this.setItem(STORAGE_KEYS.CONTACT_TOKEN, contactToken);
    }

    /**
     * Create container for iframe
     *
     * @returns container Container element
     */
    static createContainer(): HTMLDivElement {
        const container = document.createElement('div');
        container.id = 'nby-pop-instance-container';

        container.style.zIndex = '2147483647';
        container.style.display = 'initial';
        container.style.position = 'fixed';

        container.style.bottom = '20px';
        container.style.right = '20px';

        container.style.width = '310px';
        container.style.height = '200px';

        return container;
    }

    /**
     * Loads an iframe into the container and append it to the document body or a given parent element
     * @param container Container element
     * @param iframe Iframe element
     * @param parent A parent to append the iframe to
     *
     * @returns popContainer The container and iframe information
     */
    static merge(
        container: HTMLDivElement,
        iframe: HTMLIFrameElement,
        parent?: HTMLElement
    ): PopContainer {
        const popContainer: PopContainer = {
            parent: container,
            iframe,
        };
        container.appendChild(iframe);
        (parent ?? document.body).appendChild(container);
        return popContainer;
    }

    /**
     * Create iframe
     * @param url URL to load
     * @param queryParams Query parameters to add to the URL
     *
     * @returns iframe Iframe element
     */
    static createIframe(
        url: string,
        queryParams?: {
            [key: string]: string;
        }
    ): HTMLIFrameElement {
        const iframe = document.createElement('iframe', {});

        iframe.id = 'nby-pop-instance';
        iframe.style.width = '100%';
        iframe.style.height = '100%';

        iframe.sandbox.add('allow-scripts');
        iframe.sandbox.add('allow-forms');
        iframe.sandbox.add('allow-popups');
        iframe.sandbox.toggle('allow-same-origin');

        const serializedQueryParams = new URLSearchParams(queryParams ?? {});
        iframe.setAttribute(
            'src',
            url + '?' + serializedQueryParams.toString()
        );
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('marginheight', '0');
        iframe.setAttribute('marginwidth', '0');

        return iframe;
    }

    /**
     * Destroys the container
     */
    destroy(): void {
        if ((this.config.destroyable ?? true) && this.container) {
            this.container.parent.outerHTML = '';
        }
    }

    /**
     * Handle message from iframe
     * @param message Message data
     */
    onMessage(message: any): void {
        switch (message.type) {
            case 'resize':
                this.resize(
                    message.data.right,
                    message.data.bottom,
                    message.data.width,
                    message.data.height
                );
                break;
            case 'closed':
                this.destroy();
                break;
            case 'trigger-dom':
                this.toggleDom(message.data.selector, message.data.event);
                break;
            case 'store-contact':
                NirbyPop.storeContact(message.data.contact);
                break;
            case 'mark-as-impressed':
                NirbyPop.setItem('impressed', true);
                break;
            case 'answers':
                NirbyPop.setItem(STORAGE_KEYS.ANSWERS, message.data.data);
                break;
            case 'go-to-url':
                window.open(message.data.url, message.data.target);
                break;
            case 'store-persistent':
                NirbyPop.setItem('persistent', message.data.data);
                break;
        }
    }

    /**
     * Resize the iframe container
     * @param right Right offset
     * @param bottom Bottom offset
     * @param width Width
     * @param height Height
     */
    resize(right: string, bottom: string, width: string, height: string): void {
        if (!this.container) {
            return;
        }
        this.container.parent.style.width = width;
        this.container.parent.style.height = height;
        this.container.parent.style.right = right;
        this.container.parent.style.bottom = bottom;
    }

    /**
     * Triggers a DOM event on the first element matching the selector
     * @param selector Selector
     * @param event Event name
     */
    toggleDom(selector: string, event: string): void {
        const element = document.querySelector(selector) as HTMLElement;
        if (!element) {
            console.warn(
                `[Nir.by Pop] Trying to trigger event '${event}' on element with selector '${selector}', but no element was found`
            );
            return;
        }
        if (event === 'click') {
            element.click();
            return;
        }

        let eventObj;
        switch (event) {
            case 'dblclick':
                eventObj = new MouseEvent('dblclick', {
                    view: window,
                    bubbles: true,
                    cancelable: false,
                });
                break;
            default:
                eventObj = new Event(event);
        }
        element.dispatchEvent(eventObj);
    }
}
