import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlayerComponent } from './views/player/player.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from '../environments/environment';
import { PlayerTestComponent } from './views/player-test/player-test.component';
import { PlayerTestEmbedComponent } from './views/player-test-embed/player-test-embed.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { NirbyPopModule } from '@nirby/pop';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

@NgModule({
    declarations: [
        AppComponent,
        PlayerComponent,
        PlayerTestComponent,
        PlayerTestEmbedComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        HttpClientModule,
        AppRoutingModule,
        NgbModule,
        CommonModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        NirbyPopModule.forRoot({ player: { api: environment.api } }),
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(http, '/assets/i18n/', '.json'),
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        {
            provide: USE_FIRESTORE_EMULATOR,
            useValue: environment.useEmulators
                ? ['localhost', 8080]
                : undefined,
        },
    ],
    bootstrap: [AppComponent],
})
/**
 * Module for the Pop embed application.
 */
export class AppModule {}
