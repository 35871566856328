import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PlayerComponent } from './views/player/player.component';
import { environment } from '../environments/environment';
import { PlayerTestComponent } from './views/player-test/player-test.component';
import { PlayerTestEmbedComponent } from './views/player-test-embed/player-test-embed.component';

const routes: Routes = [];

if (!environment.production) {
    routes.push({ path: 'test', component: PlayerTestComponent });
    routes.push({ path: 'embed/test', component: PlayerTestEmbedComponent });
    routes.push({
        path: 'embed/test/:workspaceId',
        component: PlayerTestEmbedComponent,
    });
}

routes.push({ path: 'embed/:workspaceId', component: PlayerComponent });

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
