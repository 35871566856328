import { Injectable } from '@angular/core';
import { IMinimatch, Minimatch } from 'minimatch-browser';
import {RouteCondition} from '@nirby/models/nirby-player';

@Injectable({
    providedIn: 'root',
})
export class RouteMatcherService {
    /**
     * Appends a / to the end and start of the path if necessary and strips either index.html or index.php at
     * the end of the path
     * @param path path to be normalized
     * @private
     *
     * @returns {string} normalized path
     */
    private static normalize(path: string): string {
        let fixed = path.replace(/index.(html|php)$/, '');
        if (fixed.length === 0 || fixed[fixed.length - 1] !== '/') {
            fixed = fixed.concat('/');
        }
        if (fixed[0] !== '/') {
            fixed = '/'.concat(fixed);
        }
        return fixed;
    }

    routeConditionToRegex(route: RouteCondition | string): IMinimatch {
        if (typeof route === 'string') {
            return this.routeConditionToRegex({
                type: 'match',
                content: route,
            });
        }

        const path = RouteMatcherService.normalize(route.content);
        switch (route.type) {
            case 'match':
                return new Minimatch(path, {
                    nocomment: true,
                    nocase: true,
                });
            case 'begins-with':
                return new Minimatch(path + '**/', {
                    nocomment: true,
                    nocase: true,
                });
        }
    }

    match(route: RouteCondition | string, path: string): boolean {
        const minimatch = this.routeConditionToRegex(route);
        const normalized = RouteMatcherService.normalize(path);
        return minimatch.match(normalized);
    }
}
