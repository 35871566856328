<ng-container *ngIf="content$ | async as content">
    <nirby-small-popper
        class="nirby-popper nirby-popper-large"
        *ngIf="content.design === 'small'"
        [position]="position"
        [backgroundUrl]="backgroundUrl"
        (closed)="closed.emit()"
        (popped)="clicked.emit()"
    ></nirby-small-popper>
    <nirby-standard-popper
        class="nirby-popper nirby-popper-large"
        *ngIf="!content.design || content.design === 'standard'"
        [showCloseButton]="showCloseButton"
        [position]="position"
        [backgroundUrl]="backgroundUrl"
        [label]="content.buttonLabel"
        [body]="content.body"
        (closed)="closed.emit()"
        (popped)="clicked.emit()"
    ></nirby-standard-popper>
    <nirby-large-popper
        class="nirby-popper nirby-popper-large"
        *ngIf="content.design === 'large'"
        [showCloseButton]="showCloseButton"
        [position]="position"
        [backgroundUrl]="backgroundUrl"
        (closed)="closed.emit()"
        [label]="content.buttonLabel"
        [body]="content.body"
        (popped)="clicked.emit()"
    ></nirby-large-popper>
</ng-container>
